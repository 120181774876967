html {
  font-size: 16px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.report-style-class-insights {
  margin: 1% auto;
  width: 100%;
}
.report-style-class-ga-insights {
  margin: 1% auto;
  width: 100vw;
  height: 100vh;
}

@media print {
  html {
    font-size: 13px;
  }
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
  }

  @page {
    size: A4;
    margin: 0;
  }

  .report-style-class-wellbeing {
    width: 60rem;
    height: 60rem;
    margin-top: 23rem;
    transform: scale(1.43) translate(8.25rem);
  }
  .report-style-class-sipillars {
    width: 60rem;
    height: 60rem;
    margin-top: 23rem;
    transform: scale(1.43) translate(8.4rem);
  }
  .report-style-class-adoption {
    width: 60rem;
    height: 90rem;
    margin-top: 26rem;
    transform: scale(1.43) translate(8.4rem);
  }
  .report-style-class-modules {
    width: 60rem;
    height: 65rem;
    margin-top: 23rem;
    transform: scale(1.43) translate(8.4rem);
  }
  .report-style-class-care {
    width: 60rem;
    height: 60rem;
    margin-top: 10rem;
    transform: scale(1.4) translate(8.3rem);
  }
}

.report-style-class-insights {
  height: calc(100vw * 0.75 * 1.24);
}
.report-style-class-wellbeing {
  height: 65rem;
}
.report-style-class-sipillars {
  height: 65rem;
}
.report-style-class-adoption {
  height: 140rem;
}
.report-style-class-modules {
  height: 65rem;
}
.report-style-class-care {
  height: 65rem;
}

div[aria-labelledby='transition-modal-title'][aria-describedby='transition-modal-description'] {
  overflow-y: auto;
}

.select-date-picker-dialog .MuiPickersModal-dialogRoot {
  border-radius: 12px;
}

.select-date-picker-dialog .MuiPickersModal-dialogRoot .MuiButton-textPrimary {
  padding: 0px;
  margin: 12px 8px;
}

.select-date-picker-dialog h2 {
  font-size: 4rem;
  font-family: Averta;
}

.select-date-picker-dialog h6 {
  font-family: Averta;
}

.select-date-picker-input {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.select-date-picker-input input {
  max-width: 9.25rem;
  color: #006aff;
}

.select-date-picker-input button {
  color: #006aff;
  padding: 0;
}

.select-date-picker-input .MuiInput-underline::before,
.select-date-picker-input .MuiInput-underline::after {
  border-bottom-color: #006aff;
}

.select-time-picker-input {
  margin-left: 10px !important;
}

.select-time-picker-input input {
  max-width: 4.5rem;
  color: #006aff;
}

.select-time-picker-input button {
  color: #006aff;
  padding: 0;
}

.select-time-picker-input .MuiInput-underline::before,
.select-time-picker-input .MuiInput-underline::after {
  border-bottom-color: #006aff;
}

@media only screen and (min-width: 960px) {
  .report-style-class-insights {
    height: calc(100vw * 0.75 * 1.24);
  }
  .report-style-class-ga-insights {
    width: 100%;
  }
  .report-style-class-wellbeing {
    width: 60rem;
  }
  .report-style-class-sipillars {
    width: 60rem;
  }
  .report-style-class-adoption {
    width: 60rem;
  }
  .report-style-class-modules {
    height: 65rem;
  }
  .report-style-class-care {
    width: 60rem;
  }
}

iframe {
  border: none;
}

.markdown h2 {
  color: rgb(37, 39, 43);
  font-size: 24px;
  font-family: Averta-ExtraBold, Helvetica, Verdana, sans-serif;
  font-weight: 800;
  letter-spacing: 0px;
}

.markdown h3 {
  color: rgb(37, 39, 43);
  font-size: 20px;
  font-family: Averta-ExtraBold, Helvetica, Verdana, sans-serif;
  font-weight: 800;
  letter-spacing: 0px;
}

.markdown ul {
  list-style: none; /* Remove HTML bullets */
  padding: 0;
  margin: 0;
  color: rgb(37, 39, 43);
  font-size: 16px;
  font-family: Averta, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
}

.markdown li {
  padding-left: 16px;
  color: rgb(37, 39, 43);
  font-size: 16px;
  font-family: Averta, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
  margin-bottom: 24px;
}

.markdown ul:first-child {
  margin-top: 24px;
}

.markdown p {
  color: rgb(37, 39, 43);
  font-size: 16px;
  font-family: Averta, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
}

.markdown li::before {
  content: '\25CF'; /* Insert content that looks like bullets */
  padding-right: 6px;
  color: #006aff; /* Or a color you prefer */
  margin-left: -16px;
}

.markdown ul li ul li::before {
  content: '\2014';
  padding-right: 0.375rem;
  color: #006aff;
  margin-left: -16px;
}

.recommendations h2 {
  color: #0d7442;
  font-size: 1.5rem;
  font-family: Averta-ExtraBold, Helvetica, Verdana, sans-serif;
  font-weight: 800;
  letter-spacing: 0rem;
}

.recommendations h3 {
  color: #0d7442;
  font-size: 1.25rem;
  font-family: Averta-Semibold, Helvetica, Verdana, sans-serif;
  font-weight: 800;
  letter-spacing: 0rem;
  margin-bottom: 8px;
}

.recommendations h4 {
  color: #0d7442;
  font-size: 1rem;
  font-family: Averta-Semibold, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0rem;
}

.recommendations ul {
  list-style: none; /* Remove HTML bullets */
  padding: 0;
  margin: 0;
  color: #0d7442;
  font-size: 1rem;
  font-family: Averta, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0rem;
  line-height: 1.25rem;
}

.recommendations li {
  padding-left: 1rem;
  color: #0d7442;
  font-size: 1rem;
  font-family: Averta, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0rem;
  line-height: 1rem;
  margin-bottom: 1.25rem;
}

.recommendations ul:first-child {
  margin-top: 1rem;
}

.recommendations p,
.recommendations span {
  color: #0d7442;
  font-size: 1rem;
  font-family: Averta, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0rem;
  margin-top: 2px;
}

.recommendations u {
  color: #0d7442;
  font-size: 1rem;
  font-family: Averta, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  letter-spacing: 0rem;
  cursor: pointer;
}

.recommendations.main span {
  color: #f8fcff;
}

.recommendations li::before {
  content: '\25CF';
  padding-right: 0.375rem;
  color: #3f97ff;
  margin-left: -1rem;
}

.recommendations ul li ul li::before {
  content: '\2014';
  padding-right: 0.375rem;
  color: #3f97ff;
  margin-left: -1rem;
}
